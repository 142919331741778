import { BuildingOffice2Icon } from "@heroicons/vue/20/solid";

export const propertyExamples = [
  {
    name: "Suburban",
    markdown: `Here's an industrial (yellow) building with surface parking (orange).

    ![Suburban property screenshot](https://assets.towerhunt.com/site/simpleSuburbanProperty.jpg)`,
  },
  {
    name: "Urban",
    markdown: `Here's an office (blue) building with underground parking (orange).

    ![Urban property screenshot](https://assets.towerhunt.com/site/simpleUrbanProperty.png)`,
  },
  {
    name: "Simple",
    markdown: `Here's a parcel of land with unused development rights (life science: teal, industrial: yellow).

    ![Simple property screenshot](https://assets.towerhunt.com/site/simpleLandWithAirRights.jpg)`,
  },
  {
    name: "Complex",
    markdown: `Here's a shopping mall being redeveloped into multiple "buildings within a building" (denoted by the light blue stripe across the top of each building) involving multiple uses, multiple building heights, below-grade parking, and a condominium ownership structure (see the **UNIT** layer) where one unit is operated by a ground lessee (see the **L'HOLD** layer). One of the connected buildings is owned fee simple by a separate joint venture (see **FEE** layer where one building has no **UNIT** layer block).

    ![Complex property screenshot](https://assets.towerhunt.com/site/complexMultiBuildingProperty.png)`,
  },
];

export const whatIsAProperty = {
  order: 0,
  name: "What is a property?",
  subcategory: "Properties",
  icon: BuildingOffice2Icon,
  iconColor: "text-gray-800",
  markdown: `# What is a property?

  In Tower Hunt, a property is a container. It is not a building or a piece of land, although it can be that simple. Properties allow you to build nearly any type of physical real estate scenario, from a single parcel of unimproved land to a multi-parcel, multi-building complex with divided ownership. Most importantly, properties can be built and navigated using a simple set of commands. All you need is an understanding of the real estate and these help articles. Take a look at some examples of Tower Hunt properties (displayed as {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagrams{% /inlineRouterLink %}) to see what you can do:

  {% tabbedContainer tabs=$propertyExamples /%}

  ## Initial state

  When a property is first {% inlineRouterLink articleId="create-a-property" %}created{% /inlineRouterLink %}, it contains only a name, a street address, a location, and a fee parcel.

  ![Initial property screenshot](https://assets.towerhunt.com/site/newPropertyDiagram.jpg)

  ## Flexible

  While Tower Hunt properties can be incredibly powerful, they are designed to start simple. If you choose to invest time and effort to add datapoints to a property, you are rewarded with richer context and increased {% inlineRouterLink %}earning potential{% /inlineRouterLink %}.

  ## Next steps

  With this in mind, you're ready to get started with properties:

  - Learn how to {% inlineRouterLink articleId="create-a-property" %}create a property{% /inlineRouterLink %}.
  - Get introduced to the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, which is your primary tool for building and viewing properties.`,
};
